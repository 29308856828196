@import url('https://fonts.googleapis.com/css2?family=Reddit+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Disable text selection */
* {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.reddit-sans {
  font-family: "Reddit Sans", serif;
  font-optical-sizing: auto;
}
